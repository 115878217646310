import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'

export const MUTATION_STATE = (payloadType: string): DocumentNode => gql`
    fragment MutationState${payloadType}Fields on ${payloadType} {
    errors
    success
    }
`

export const ALERT_FIELDS = gql`
  fragment AlertFields on AlertNotification {
    type
    active
    event
    genre
    message
  }
`

export const SEND_ACCEPTANCES_FIELDS = gql`
  fragment SendToAcceptanceFields on SendCampaignCreationsToAcceptancePayload {
    acceptances {
      acceptances {
        agglomeration
        city
        creationName
        id
        rejectionDetails
        status
        creationId
        alerts {
          id
          active
          message
          type
          genre
        }
      }
      building
    }
    info
  }
`

export const CREATION_FIELDS = gql`
  fragment CreationFields on Creation {
    alerts {
      active
      event
    }
    approved
    duration
    archivedAt
    createdAt
    containsAlcohol
    archiveReason
    details
    file {
      filename
      isVideo
      url
      mimeType
    }
    id
    mediaFormat
    thumbnailUrl
    uuid
    state
    emissionStartDate
    schedules {
      startDate
      endDate
    }
    impressions
    audience
  }
`

export const APPROVAL_FIELDS = gql`
  fragment ApprovalFields on Approval {
    approved
    category
    id
  }
`

export const AGENCY_FIELDS = gql`
  fragment AgencyFields on Agency {
    id
    name
    company {
      apartmentNumber
      buildingNumber
      city
      crmId
      id
      name
      nip
      postalCode
      street
    }
    supervisors {
      id
      firstName
      lastName
    }
    clientId
    agencyType
    currencyType
    origin
    billingAllowance
    discount
    allocatedTime
    agencyMediums {
      allocatedTime
      media {
        id
      }
    }
    mediumAllocatedTime
    withAllocatedTime
    allowedTargets {
      id
      name
    }
  }
`

export const COMPANY_FIELDS = gql`
  fragment CompanyFields on AmbasadorCompany {
    id
    name
  }
`
export const ALL_ROLES_FIELDS = gql`
  fragment AllRolesFields on Role {
    id
    name
    baseRole
  }
`

export const ROLE_FIELDS = gql`
  fragment RoleFields on Role {
    id
    name
    baseRole
    permissionElementRoles {
      id
      name
      permissions {
        name
        value
      }
    }
  }
`

export const MEDIUM_PLACE_DETAILS_FIELDS = gql`
  fragment MediumPlaceDetailsFields on MediumPlaceDetails {
    id
    name
  }
`

export const MEDIUM_FIELDS = gql`
  ${MEDIUM_PLACE_DETAILS_FIELDS}
  fragment MediumFields on Medium {
    address
    agglomeration {
      ...MediumPlaceDetailsFields
    }
    asId
    building {
      ...MediumPlaceDetailsFields
    }
    city {
      ...MediumPlaceDetailsFields
    }
    comment
    id
    images {
      imageUrl
      thumbnailUrl
    }
    lat
    lon
    mediaFormat
    placement
    screenCount
    status
    vac
    pois {
      edges {
        node {
          id
          category
        }
      }
    }
    creationFormat
  }
`

export const CAMPAIGN_MEDIA_CRITERIA_SEARCH_FIELDS = gql`
  fragment CampaignMediaCriteriaSearchFields on CampaignMediaCriteriaSearchType {
    mediumFormat
    pois
  }
`

export const USER_FIELDS = gql`
  fragment UserFields on User {
    avatar {
      url
    }
    agencies {
      id
      clientId
      name
      withAllocatedTime
    }
    email
    firstName
    id
    lastName
    preferredLocale
    priorities
    roles {
      baseRole
      id
      name
    }
    status
  }
`

export const USER_DETAILS_FIELDS = gql`
  fragment UserDetailsFields on User {
    avatar {
      url
    }
    email
    firstName
    id
    lastName
    preferredLocale
    priorities
    agencies {
      id
      name
      withAllocatedTime
    }
    roles {
      permissionElementRoles {
        title
        name
        id
        permissions {
          title
          id
          name
          value
        }
      }
      baseRole
      id
      name
    }
    status
    subscriptionAccount
  }
`

export const NEW_ROLE_FIELDS = gql`
  fragment NewRolePermissions on NewRolePermissionsConnection {
    nodes {
      id
      name
      permissions
    }
  }
`
export const CAMPAIGN_DETAILS_FIELDS = gql`
  ${MEDIUM_FIELDS}
  fragment CampaignDetailsFields on CampaignDetails {
    everUsedMedia {
      nodes {
        ...MediumFields
      }
    }
    emissionType
    currentImpressions
    currentAudience
    durationDays
    remainingDays
    target
    targetCompletion
    totalImpressions
    totalAudience
  }
`

export const CAMPAIGN_FOR_DETAILS_FIELDS = gql`
  ${ALERT_FIELDS}
  ${AGENCY_FIELDS}
  ${CAMPAIGN_MEDIA_CRITERIA_SEARCH_FIELDS}
  ${CREATION_FIELDS}
  fragment CampaignForDetailsFields on Campaign {
    alerts {
      ...AlertFields
    }
    acceptanceAlerts {
      ...AlertFields
    }
    brainState
    acceptanceStatus
    briefName
    commercialAttribute
    budget
    budgetCurrency
    creations {
      ...CreationFields
    }
    agency {
      ...AgencyFields
    }
    endDate
    id
    impressions
    emissionType
    mediaCriteriaSearch {
      ...CampaignMediaCriteriaSearchFields
    }
    name
    note
    contractNote
    offer
    priority
    priorityRanges
    reservationTill
    startDate
    status
    supervisors {
      id
      firstName
      lastName
    }
    target
    uuid
    currentValue {
      currency
      value
      valueCents
    }
    estimatedValue {
      currency
      value
      valueCents
    }
    createdAt
    audience
    mediaDuration
  }
`

export const CAMPAIGN_CHART_FILTERS_FIELDS = gql`
  fragment CampaignChartFiltersFields on CampaignChartFilters {
    agglomerations
    buildings
    cities
    mediaAsIds
    pois
  }
`

export const CAMPAIGN_ACCEPTANCES_FIELDS = gql`
  fragment CampaignAcceptancesFields on CreationAcceptancesList {
    acceptances {
      id
      agglomeration
      city
      creationName
      rejectionDetails
      status
      creationId
      alerts {
        id
        active
        message
        type
        genre
      }
    }
    building
  }
`

export const CAMPAIGN_IMPRESSIONS_FIELDS = gql`
  fragment CampaignImpressionsFields on CampaignImpressions {
    audience
    date
    impressions
    totalAudience
    totalImpressions
    estimated
  }
`

export const CAMPAIGN_PROGRESS_FIELDS = gql`
  fragment CampaignProgressFields on CampaignProgress {
    percent
    status
  }
`

export const CAMPAIGN_FILTERS_VALUES_FIELDS = gql`
  fragment CampaignFiltersValuesFields on CampaignFiltersValues {
    cities
    agencies
    mediaFormats
    statuses
    targets
    commercialAttributes
  }
`

export const CAMPAIGN_MEDIUM_USAGE_FIELDS = gql`
  fragment CampaignMediumFields on CampaignMedium {
    id
    used
  }
`
export const CREATION_MEDIUM_USAGE_FIELDS = gql`
  fragment CreationMediumFields on CreationMedium {
    id
    used
  }
`
export const UPDATE_CREATION_EMISSIONS_NUMBER_FIELDS = gql`
  fragment UpdateCreationImpressionsFields on UpdateCreationImpressionsPayload {
    creation {
      impressions
    }
    brainState
  }
`

export const UPDATE_CREATION_AUDIENCE_NUMBER_FIELDS = gql`
  fragment UpdateCreationAudienceFields on UpdateCreationAudiencePayload {
    creation {
      audience
    }
    brainState
  }
`
export const INVENTORY_ALERTS = gql`
  fragment InventoryAlertFields on InventoryAlert {
    cities
    id
    week
  }
`
