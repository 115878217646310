import { Agency } from './agency'
import { PermissionElementRole } from '../pages/Roles/models/role-form-slice.model'

export interface User {
  avatar: AvatarFile | null
  agencies: Agency[]
  email: string
  firstName: string
  id: string
  lastName: string
  password: string
  preferredLocale: UserLocale
  priorities: Priority[]
  roles: Role[]
  status: UserStatus
  token: string | undefined
  subscriptionAccount: boolean
}

export interface AvatarFile {
  url: string
}

export type UserLocale = 'pl' | 'en'

export enum BaseRole {
  ADMIN = 'admin',
  CLIENT = 'client',
  EMPLOYEE = 'employee',
  PLANNER = 'planner',
  ACCOUNT = 'account',
  SUPPORT = 'support',
}

export interface Role {
  permissionElementRoles?: PermissionElementRole[]
  baseRole: BaseRole
  id: string
  name: string
}

export enum UserSortColumn {
  AGENCY_NAME = 'agency_name',
  FIRST_NAME = 'firstName',
  ROLES = 'roles',
  EMAIL = 'email',
  STATUS = 'status',
}

export enum UserStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
  DEACTIVATED = 'deactivated',
  SUBSCRIPTION = 'subscription',
}

export type Priority = number

export type UserFilters = {
  statuses?: UserStatus[]
  roles?: BaseRole[]
  agencies?: string[]
}
