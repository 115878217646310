import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  BrainState,
  Filters,
  filtersFormDefault,
  GroupedMedia,
  MEDIA_SOURCE,
  MediaUsageCounter,
  UseMediaSelection,
  UseMediaSelectionProps,
} from './types'
import { MediaFormat, Medium, MediumPlaceDetails, MediumUsage } from 'types/campaign'
import debounce from 'lodash/debounce'
import { groupMediaForCheckboxes } from './grouper'
import VariableUtils from 'utils/variable'
import { useImmer } from 'use-immer'
import { produce } from 'immer'
import { switchGroupFold, switchGroupState, switchMediumState } from './switcher'
import { countTailMedia } from './counter'
import {
  fetchAllMedia,
  fetchCampaignMediaUsage,
  fetchCreationMediaUsage,
  fetchCreationMediaUsagePublic,
  fetchMediaUsedInCampaign,
  fetchMediaUsedInCampaignPublic,
  updateCampaignMediaUsage,
  updateCreationMediaUsage,
} from './api'
import { collectMedia } from './collector'
import { collectFilters, filterGroupedMedia } from './filters'
import { DEBOUNCE_POST_DELAY_DEFAULT } from 'constant'
import { useQuery } from '@apollo/client'
import { GetMediaTemplateResult } from 'api/media/types'
import GET_MEDIA_TEMPLATES from 'api/media/getMediaTemplates'
import { AppContext } from '../../contexts/AppContext'

const useMediaSelection = ({
  ids: { campaignId, campaignUuid, creationId, creationUuid },
  mediumFormat,
  autoSave,
  readOnly,
  onLoading,
}: UseMediaSelectionProps): UseMediaSelection => {
  const [mediaSource, setMediaSource] = useState<MEDIA_SOURCE | undefined>(undefined)
  const [groupedMedia, setGroupedMedia] = useImmer<GroupedMedia | undefined>(undefined)
  const [initialGroupedMedia, setInitialGroupedMedia] = useState<GroupedMedia | undefined>(
    undefined
  )

  const [mediaUsage, setMediaUsage] = useState<MediumUsage[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  const [templateLoader, setTemplateLoader] = useState<boolean>(true)
  const [selectAllState, setSelectAllState] = useState<boolean>(true)
  const [counter, setCounter] = useState<MediaUsageCounter>({ used: 0, all: 0 })
  const [filters, setFilters] = useState<Filters>(filtersFormDefault)
  const checkUsedMediaWorker: Worker = useMemo(
    () => new Worker(new URL('./checkUsedMedia.worker.ts', import.meta.url)),
    []
  )

  const indexOfMediumFormat = Object.values(MediaFormat).indexOf(
    mediumFormat as unknown as MediaFormat
  )
  const { userData } = useContext(AppContext)

  const { data: templateMedia, refetch: refetchMediumTemplates } = useQuery<GetMediaTemplateResult>(
    GET_MEDIA_TEMPLATES,
    {
      fetchPolicy: 'network-only',
      variables: {
        mediumFormat: Object.keys(MediaFormat)[indexOfMediumFormat],
      },
      skip: !userData.signedIn,
    }
  )

  const saveMediaDebounced = useCallback(
    debounce(
      mediaUsage => saveMedia(mediaUsage, undefined, onLoading),
      DEBOUNCE_POST_DELAY_DEFAULT
    ),
    [initialGroupedMedia]
  )

  useEffect(() => {
    if (campaignId && !campaignUuid && !creationId && !creationUuid) {
      setMediaSource(MEDIA_SOURCE.CAMPAIGN)
    } else if (campaignId && !campaignUuid && creationId && !creationUuid) {
      setMediaSource(MEDIA_SOURCE.CREATION_PRIV)
    } else if (!campaignId && campaignUuid && !creationId && creationUuid) {
      setMediaSource(MEDIA_SOURCE.CREATION_PUB)
    } else {
      throw new Error('Unacceptable choice of hook input arguments.')
    }
  }, [])

  useEffect(() => {
    if (mediaSource) {
      debounce(() => prepareData(mediaSource), DEBOUNCE_POST_DELAY_DEFAULT)()
    }
  }, [mediaSource])

  useEffect(() => {
    if (groupedMedia) {
      setCounter(countTailMedia(groupedMedia))
      const mediaUsage = collectMedia(groupedMedia).map((medium: Medium) => ({
        id: medium.id,
        used: medium.used,
      }))

      setMediaUsage(mediaUsage)
      if (autoSave) {
        saveMediaDebounced(mediaUsage)
      }
    }
  }, [groupedMedia])

  useEffect(() => {
    setSelectAllState(counter.used === counter.all)
  }, [counter])

  checkUsedMediaWorker.onmessage = (e: MessageEvent<string>) => {
    const workerData = JSON.parse(e.data) as unknown as GroupedMedia

    setGroupedMedia(workerData)
    setLoading(false)
    setTemplateLoader(false)
  }
  const prepareData = async (mediaSource: MEDIA_SOURCE) => {
    const media: Medium[] = await getMedia(mediaSource).then(res => {
      return res
        .map((m: Medium) => ({ ...m, used: false }))
        .sort((mediumA, mediumB) => {
          if (!mediumA.agglomeration?.name || !mediumB.agglomeration?.name) {
            return 0
          }

          if (mediumA.agglomeration.name !== mediumB.agglomeration.name) {
            return mediumA.agglomeration.name.localeCompare(mediumB.agglomeration.name)
          }

          return mediumA.city.name.localeCompare(mediumB.city.name)
        })
    })

    if (media.length > 0) {
      const groupedMedia: GroupedMedia = groupMediaForCheckboxes(media)
      setInitialGroupedMedia(groupedMedia)

      if (!VariableUtils.isEmptyObject(groupedMedia)) {
        const mediaUsage: MediumUsage[] = await getMediaUsage(mediaSource)
        checkUsedMediaWorker.postMessage(
          JSON.stringify({
            groupedMedia,
            usedMediaIds: mediaUsage.filter(usedMedia => usedMedia.used).map(media => media.id),
          })
        )
        setFilters(collectFilters(groupedMedia))
      }
    }

    /**
     * If there are no media, we can stop loading, so that the user can see the empty state
     */
    if (!media.length) {
      setLoading(false)
    }
  }

  const getMedia = async (mediaSource: MEDIA_SOURCE): Promise<Medium[]> => {
    switch (mediaSource) {
      case MEDIA_SOURCE.CAMPAIGN:
        return await fetchAllMedia(mediumFormat, userData.withAllocatedTime, campaignId!)
      case MEDIA_SOURCE.CREATION_PRIV:
        return await fetchMediaUsedInCampaign(campaignId!)
      case MEDIA_SOURCE.CREATION_PUB:
        return await fetchMediaUsedInCampaignPublic(campaignUuid!)
      default:
        return []
    }
  }

  const getMediaUsage = async (mediaSource: MEDIA_SOURCE): Promise<MediumUsage[]> => {
    switch (mediaSource) {
      case MEDIA_SOURCE.CAMPAIGN:
        return await fetchCampaignMediaUsage(campaignId!)
      case MEDIA_SOURCE.CREATION_PRIV:
        return await fetchCreationMediaUsage(creationId!)
      case MEDIA_SOURCE.CREATION_PUB:
        return await fetchCreationMediaUsagePublic(creationUuid!)
      default:
        return []
    }
  }

  const onFoldTail = (id: MediumPlaceDetails['id']) =>
    void setGroupedMedia(
      produce(draft => {
        switchGroupFold(id, draft)
      })
    )

  const onCheckTail = (
    id: MediumPlaceDetails['id'],
    state?: boolean,
    mediumIds?: Medium['id'][]
  ): void =>
    void setGroupedMedia(
      produce(draft => {
        switchGroupState(id, draft, state, mediumIds)
      })
    )

  const onCheckMedium = (id: Medium['id'], newState?: boolean): void =>
    void setGroupedMedia(
      produce(draft => {
        switchMediumState(id, draft, newState)
      })
    )

  const selectMedia = (mediumIds: Medium['id'][]): void => {
    setTemplateLoader(true)
    checkUsedMediaWorker.postMessage(
      JSON.stringify({
        groupedMedia: initialGroupedMedia,
        usedMediaIds: mediumIds,
      })
    )
  }

  const saveMedia = async (
    mediaUsage: MediumUsage[],
    setShouldSaveMedia?: Dispatch<SetStateAction<boolean>>,
    onLoading?: (isLoading: boolean) => void
  ): Promise<BrainState | undefined> => {
    if (!mediaUsage) return

    onLoading && onLoading(true)

    let brainState: BrainState | undefined

    if (mediaSource === MEDIA_SOURCE.CAMPAIGN) {
      const result = await updateCampaignMediaUsage(campaignId!, mediaUsage)
      brainState = result.data?.updateCampaignMedia.campaign.brainState
    } else if (mediaSource === MEDIA_SOURCE.CREATION_PRIV) {
      if (!readOnly) {
        await updateCreationMediaUsage(creationId!, mediaUsage)
      }
    }

    onLoading && onLoading(false)
    setShouldSaveMedia && setShouldSaveMedia(false)

    return brainState
  }

  const onFilter = (filters: Filters) =>
    void setGroupedMedia(
      produce(draft => {
        filterGroupedMedia(draft, filters)
      })
    )

  const onClearFilters = () => {
    if (groupedMedia) {
      const filters: Filters = collectFilters(groupedMedia)

      setFilters(filters)
      onFilter(filters)
    }
  }

  return {
    counter,
    countTailMedia,
    filters,
    groupedMedia,
    loading,
    mediaUsage,
    mediumTemplates: templateMedia?.mediaTemplatesList.nodes,
    onCheckMedium,
    onCheckTail,
    onClearFilters,
    onFilter,
    onFoldTail,
    refetchMediumTemplates,
    saveMedia,
    selectAllState,
    setSelectAllState,
    selectMedia,
    setFilters,
    setGroupedMedia,
    templateLoader,
  }
}

export default useMediaSelection
