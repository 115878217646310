import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormRow from 'components/Form/FormRow'
import FormColumn from 'components/Form/FormColumn'
import Input, { OnInputChangeFn, OnManyInputChangeFn } from 'components/Form/Input'
import service from './services/details-form.service'
import offerService from './services/offer-form.service'
import { CampaignFormContext, detailsValuesDefault } from 'contexts/CampaignFormContext'
import usePrevious from 'hooks/usePrevious'
import VariableUtils from 'utils/variable'
import { InputMask } from 'utils/inputMask'
import Link from 'components/Link'
import DetailsFormMediaFormat from './DetailsFormMediaFormat'
import { DetailsFormErrors } from './types'
import Modal from 'components/Modal'
import MediaList from 'pages/MediaList'
import Button from 'components/Form/Button'
import Select from 'components/Form/Select'
import {
  ADDITIONAL_OFFER,
  CampaignAcceptanceStatus,
  CampaignStatus,
  CampaignTarget,
  OFFER,
  OrganizationAttributes,
} from 'types/campaign'
import Slider from 'components/Form/Slider'
import { AppContext } from 'contexts/AppContext'
import TsxUtils from 'utils/tsx'
import { CAMPAIGN_ACTION, CAMPAIGN_FORM } from 'constant/authorization'
import { Features } from 'constant/features'
import {
  ADDITIONAL_BEST_EFFORT_PRIORITY,
  DEBOUNCE_POST_DELAY_DEFAULT,
  MEDIA_DURATIONS,
} from 'constant'
import debounce from 'lodash/debounce'
import InputTitle from 'components/Form/InputTitle'
import MediaSelectionModal from './MediaSelectionModal'
import { BaseRole } from 'types/user'
import { useDispatch, useSelector } from 'react-redux'
import { detailsFormActions } from './store/details-form-slice'
import './DetailsForm.scss'
import AttributesModal from './AttributesModal'
import { RootState, store } from '../../../../store'
import OrganizationAttributesService from './services/organization-attributes.service'
import { campaignActions } from '../../store/campaign-slice'
import { AgencyType } from '../../../../types/agency'

const DetailsForm: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setTriggerFetchCampaign, setTriggerCreations } = useContext(CampaignFormContext)
  const { allowedFor, userData, isFeatureActive } = useContext(AppContext)
  const [errors, setErrors] = useState<DetailsFormErrors>()
  const [isMediaListModalOpen, setIsMediaListModalOpen] = useState<boolean>(false)
  const [isMediaSelectionModalOpen, setIsMediaSelectionModalOpen] = useState<boolean>(false)
  const [isAttributesModalOpen, setIsAttributesModalOpen] = useState<boolean>(false)
  const [availablePriorities, setAvailablePriorities] = useState<number[]>([
    ...userData.user!.priorities,
  ])
  const { disableMediaSelection, disableUsedMedia, targetOptions } = useSelector(
    (state: RootState) => state.detailsForm
  )
  const { basicsValues, detailsValues } = useSelector((state: RootState) => state.campaign.form)
  const { campaignId, status: campaignStatus } = basicsValues
  const previousValues = usePrevious(detailsValues)

  const onInputChange: OnInputChangeFn = (
    value: string | string[] | null,
    id: string | undefined
  ) => {
    const details = { ...detailsValues }

    if (id === 'mediumFormat') {
      details.offer = null
    }

    setTriggerCreations(true)
    store.dispatch(
      campaignActions.updateDetailsValues({
        ...details,
        [id ?? '']: value,
      })
    )
  }

  const onManyInputChange: OnManyInputChangeFn = (newValues: Record<string, unknown>) => {
    /**
     * If the commercial attribute is set to best effort, the priority should be set to 3
     * but only when:
     * - the campaign is in sketch status
     * - the campaign is in pending acceptation status
     */
    if (
      'commercialAttribute' in newValues &&
      newValues.commercialAttribute === 'best_effort' &&
      (campaignStatus === CampaignStatus.SKETCH ||
        detailsValues.acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation)
    ) {
      newValues = { ...newValues, priority: ADDITIONAL_BEST_EFFORT_PRIORITY }
    }

    if ('mediumFormat' in newValues) {
      detailsValues.offer = null
    }

    setTriggerCreations(true)

    store.dispatch(campaignActions.updateDetailsValues(newValues))
  }

  const onUpdateOrganizationAttributes = (attributes: OrganizationAttributes) => {
    void OrganizationAttributesService.update(campaignId, attributes)
  }

  const debouncedHandleSubmit = useCallback(
    debounce(service.handleSubmit, DEBOUNCE_POST_DELAY_DEFAULT),
    []
  )

  useEffect(() => {
    if (campaignId) {
      service.fetchValues(campaignId, detailsValues)
    }
  }, [campaignId])

  useEffect(() => {
    const readyToTouch =
      previousValues !== undefined &&
      !VariableUtils.isDeepEqual(previousValues, detailsValuesDefault) &&
      detailsValues.fetched

    if (readyToTouch) {
      const touched = !VariableUtils.isDeepEqual(previousValues, detailsValues)

      if (touched) {
        service.validate(detailsValues, setErrors)
        dispatch(detailsFormActions.setDisableUsedMedia(true))
        dispatch(detailsFormActions.setDisableMediaSelection(true))
      }
    }
  }, [detailsValues])

  useEffect(() => {
    if (!service.hasErrors(errors)) {
      debouncedHandleSubmit(
        campaignId,
        detailsValues,
        setTriggerFetchCampaign,
        userData.user?.roles
      )
    }
  }, [errors, debouncedHandleSubmit])

  const goToMediaList = async (): Promise<void> => {
    setIsMediaListModalOpen(true)
  }

  const { min: priorityMin, max: priorityMax } = service.getMinMaxFromUserPriorities(
    userData.user!.priorities,
    detailsValues.commercialAttribute
  )

  useEffect(() => {
    if (detailsValues.commercialAttribute === 'best_effort') {
      setAvailablePriorities([ADDITIONAL_BEST_EFFORT_PRIORITY, ...userData.user!.priorities])
      return
    }

    setAvailablePriorities([...userData.user!.priorities])
  }, [userData.user, detailsValues.commercialAttribute])

  useEffect(() => {
    if (detailsValues.fetched) {
      const campaignPriority = detailsValues.priority

      if (campaignPriority > priorityMax) {
        onInputChange(priorityMax, 'priority')
      } else if (campaignPriority < priorityMin) {
        onInputChange(priorityMin, 'priority')
      }
    }
  }, [detailsValues.fetched])

  return (
    <div className='DetailsForm'>
      <FormRow className='DetailsForm__row'>
        <DetailsFormMediaFormat
          value={detailsValues.mediumFormat}
          onChange={onInputChange}
          disabled={!allowedFor({ template: CAMPAIGN_FORM.MEDIA_TYPES, status: campaignStatus })}
        />
      </FormRow>
      {allowedFor({ template: CAMPAIGN_FORM.OFFER, status: campaignStatus }) && (
        <FormRow className='DetailsForm__row'>
          <Select
            clearable
            id='offer'
            onChange={value => {
              /**
               * We should not change the offer type if we received from the backend
               * that it's additional offer type.
               * */
              if (
                detailsValues.offer &&
                (detailsValues.offer === ADDITIONAL_OFFER.NETWORK_G14_PLUS ||
                  detailsValues.offer === ADDITIONAL_OFFER.NETWORK_A14_PLUS)
              ) {
                return
              }

              onInputChange(value, 'offer')
            }}
            options={offerService.getOptions(
              detailsValues.mediumFormat,
              basicsValues.agency?.rawData?.agencyType
            )}
            placeholder={t('form.campaign.details.offerPlaceholder')}
            title={t('common.offerType')}
            value={detailsValues.offer}
          />
        </FormRow>
      )}

      <FormRow className='DetailsForm__row DetailsForm__row--locations'>
        <InputTitle title={t('common.locations')} />

        <div className='DetailsForm__media'>
          {(allowedFor({
            template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
            status: campaignStatus,
          }) ||
            detailsValues.offer !== OFFER.RUN_ON_NETWORK) && (
            <Button
              onClick={() => setIsMediaSelectionModalOpen(true)}
              disabled={
                !detailsValues.offer ||
                disableMediaSelection ||
                !allowedFor({ template: CAMPAIGN_FORM.MEDIA, status: campaignStatus })
              }
            >
              {t('common.select')}
            </Button>
          )}
          {(allowedFor({
            template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
            status: campaignStatus,
          }) ||
            detailsValues.offer !== OFFER.AGGLOMERATIONS) && (
            <Link
              className={
                !allowedFor({
                  template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
                  status: campaignStatus,
                }) && detailsValues.offer === OFFER.RUN_ON_NETWORK
                  ? 'DetailsForm__media--list'
                  : 'DetailsForm__media--list DetailsForm__media--list--padded'
              }
              onClick={goToMediaList}
              disabled={
                disableUsedMedia ||
                !allowedFor({ template: CAMPAIGN_FORM.MEDIA, status: campaignStatus })
              }
            >
              {t('common.usedMedia')}
            </Link>
          )}
        </div>
      </FormRow>
      <FormRow className='DetailsForm__row'>
        <Select
          id='target'
          title={t('form.campaign.details.target')}
          value={detailsValues.target}
          onChange={onInputChange}
          options={targetOptions}
          disabled={!allowedFor({ template: CAMPAIGN_FORM.TARGET, status: campaignStatus })}
        />
      </FormRow>
      {/*todo: uncomment when emissionType will be ready on stage*/}
      {/*<FormRow className='DetailsForm__row'>*/}
      {/*  <FormColumn>*/}
      {/*    <InputTitle*/}
      {/*      title={t('form.campaign.details.emissionType')}*/}
      {/*      help={t('form.campaign.details.synchronizedEmissionHelp')}*/}
      {/*    />*/}
      {/*    <Checkbox*/}
      {/*      id='emissionType'*/}
      {/*      checked={values.emissionType === EmissionType.SYNCHRONIZED}*/}
      {/*      disabled={disabledUsedMedia}*/}
      {/*      onChange={(event): void => {*/}
      {/*        onInputChange(*/}
      {/*          event.target.checked ? EmissionType.SYNCHRONIZED : EmissionType.STANDARD,*/}
      {/*          'emissionType'*/}
      {/*        )*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {t('form.campaign.details.synchronizedEmission')}*/}
      {/*    </Checkbox>*/}
      {/*  </FormColumn>*/}
      {/*</FormRow>*/}
      <FormRow className='DetailsForm__row'>
        <FormColumn>
          {detailsValues.target === CampaignTarget.BUDGET && (
            <Input
              id='budget'
              title={`${t(
                'form.campaign.details.maxBudget'
              )} [${detailsValues.budgetCurrency.toUpperCase()}]`}
              help={t('form.campaign.details.budgetHelp')}
              value={detailsValues.budget.toString()}
              onChange={onInputChange}
              maskType={InputMask.FLOAT}
              errors={errors?.budget}
              disabled={
                !isFeatureActive(Features.CAMPAIGN_TARGET) ||
                !allowedFor({ template: CAMPAIGN_FORM.BUDGET, status: campaignStatus })
              }
            />
          )}

          {detailsValues.target === CampaignTarget.IMPRESSIONS && (
            <>
              <Input
                id='emissions'
                title={t('common.numberOfEmissions')}
                help={t('form.campaign.details.emissionsHelp')}
                dataCy={'number-of-emissions'}
                value={detailsValues.emissions.toString()}
                onChange={onInputChange}
                maskType={InputMask.INTEGER}
                errors={errors?.emissions}
                disabled={
                  !allowedFor({ template: CAMPAIGN_FORM.EMISSIONS, status: campaignStatus })
                }
              />

              {allowedFor({
                template: {
                  roles: [BaseRole.ADMIN],
                },
              }) && (
                <>
                  <Link
                    className={'DetailsForm__hiddenTarget--link'}
                    onClick={() =>
                      store.dispatch(
                        campaignActions.updateDetailsValues({
                          ...detailsValues,
                          hiddenImpressionsActive: !detailsValues.hiddenImpressionsActive,
                        })
                      )
                    }
                  >
                    {t(
                      `common.${
                        detailsValues.hiddenImpressionsActive
                          ? 'removeHiddenTarget'
                          : 'showHiddenTarget'
                      }`
                    )}
                  </Link>
                  {detailsValues.hiddenImpressionsActive && (
                    <Input
                      id='hiddenImpressions'
                      title={t('common.hiddenTarget')}
                      help={
                        <>
                          <strong>{t('form.campaign.details.actualPlannedTarget')}</strong>&nbsp;
                          {t('form.campaign.details.emissionsHelp')}
                        </>
                      }
                      value={detailsValues.hiddenImpressions.toString()}
                      onChange={onInputChange}
                      maskType={InputMask.INTEGER}
                      errors={errors?.hiddenImpressions}
                      disabled={
                        !allowedFor({ template: CAMPAIGN_FORM.EMISSIONS, status: campaignStatus })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          {detailsValues.target === CampaignTarget.AUDIENCE && (
            <>
              <Input
                id='audience'
                title={t('common.numberOfAudience')}
                dataCy={'number-of-audience'}
                value={detailsValues.audience.toString()}
                onChange={onInputChange}
                maskType={InputMask.INTEGER}
                errors={errors?.audience}
                disabled={
                  !allowedFor({ template: CAMPAIGN_FORM.EMISSIONS, status: campaignStatus })
                }
              />

              {allowedFor({
                template: {
                  roles: [BaseRole.ADMIN],
                },
              }) && (
                <>
                  <Link
                    className={'DetailsForm__hiddenTarget--link'}
                    onClick={() =>
                      store.dispatch(
                        campaignActions.updateDetailsValues({
                          ...detailsValues,
                          hiddenAudienceActive: !detailsValues.hiddenAudienceActive,
                        })
                      )
                    }
                  >
                    {t(
                      `common.${
                        detailsValues.hiddenAudienceActive
                          ? 'removeHiddenTarget'
                          : 'showHiddenTarget'
                      }`
                    )}
                  </Link>
                  {detailsValues.hiddenAudienceActive && (
                    <Input
                      id='hiddenAudience'
                      title={t('common.hiddenTarget')}
                      help={
                        <>
                          <strong>{t('form.campaign.details.actualPlannedTarget')}</strong>&nbsp;
                          {t('form.campaign.details.emissionsHelp')}
                        </>
                      }
                      value={detailsValues.hiddenAudience.toString()}
                      onChange={onInputChange}
                      maskType={InputMask.INTEGER}
                      errors={errors?.hiddenAudience}
                      disabled={
                        !allowedFor({ template: CAMPAIGN_FORM.EMISSIONS, status: campaignStatus })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </FormColumn>
      </FormRow>
      <FormRow
        className={
          'DetailsForm__row' +
          TsxUtils.extraStyle(
            !allowedFor({ template: CAMPAIGN_FORM.PRIORITY, status: campaignStatus }),
            'DetailsForm--hidden'
          )
        }
      >
        <FormColumn>
          <Slider
            id='priority'
            title={t('common.priority')}
            help={t('form.campaign.details.priorityHelp', { priorityMin, priorityMax })}
            value={detailsValues.priority}
            onChange={onInputChange}
            minLabel={t('priority.low')}
            maxLabel={t('priority.high')}
            marks={availablePriorities}
            showTrack={false}
            useFixedValues={detailsValues.commercialAttribute === 'best_effort'}
          />
        </FormColumn>
      </FormRow>
      <FormRow className='DetailsForm__row__media-duration'>
        {basicsValues.agency?.rawData?.agencyType === AgencyType.internal && (
          <FormColumn>
            <Slider
              disabled={
                !allowedFor({
                  template: CAMPAIGN_FORM.EDIT_MEDIA_DURATION,
                  status: campaignStatus,
                })
              }
              id='mediaDuration'
              title={t('common.mediaDuration')}
              value={detailsValues.mediaDuration}
              onChange={onInputChange}
              marks={MEDIA_DURATIONS}
              step={MEDIA_DURATIONS[1] - MEDIA_DURATIONS[0]}
            />
          </FormColumn>
        )}
      </FormRow>
      {allowedFor({ template: CAMPAIGN_ACTION.CAMPAIGN_ATTRIBUTES }) && (
        <FormRow className='DetailsForm__row'>
          <FormColumn>
            <InputTitle title={t('attributesSelection.title')} />
            <Button onClick={() => setIsAttributesModalOpen(true)}>{t('common.select')}</Button>
          </FormColumn>
        </FormRow>
      )}
      {isAttributesModalOpen && (
        <AttributesModal
          isOpen={isAttributesModalOpen}
          setIsOpen={setIsAttributesModalOpen}
          commercialAttribute={detailsValues.commercialAttribute}
          organizationAttribute={detailsValues.organizationAttribute}
          onManyInputChange={onManyInputChange}
          updateOrganizationAttributes={onUpdateOrganizationAttributes}
        />
      )}
      {isMediaSelectionModalOpen && (
        <MediaSelectionModal
          campaignId={campaignId}
          isOpen={isMediaSelectionModalOpen}
          mediumFormat={detailsValues.mediumFormat}
          setIsOpen={setIsMediaSelectionModalOpen}
          withFilters={
            allowedFor({
              template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
              status: campaignStatus,
            }) || detailsValues.offer !== OFFER.AGGLOMERATIONS
          }
        />
      )}
      {isMediaListModalOpen && (
        <Modal
          title={t('common.usedMedia')}
          isOpen={isMediaListModalOpen}
          onClose={(isOpen: boolean) => void setIsMediaListModalOpen(isOpen)}
          footer={
            <Button onClick={() => void setIsMediaListModalOpen(false)}>{t('common.back')}</Button>
          }
        >
          <MediaList
            campaignId={campaignId}
            mediumFormat={detailsValues.mediumFormat}
          />
        </Modal>
      )}
    </div>
  )
}

export default DetailsForm
