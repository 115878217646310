import { ADDITIONAL_OFFER, MediaFormat, OFFER } from 'types/campaign'
import { SelectOption } from 'components/Form/Select'
import i18n from 'i18n'
import { AgencyType } from '../../../../../types/agency'

export default class OfferFormService {
  /**
   * Gets offer options based on selected medium format and agency type
   *
   * DG_CL and DG_CS:
   * - RUN_ON_NETWORK, AGGLOMERATIONS, MEDIA_SELECTION
   * DG_IN:
   * - RUN_ON_NETWORK, AGGLOMERATIONS, BUILDINGS, MEDIA_SELECTION, G14_PLUS, A14_PLUS
   *
   *
   * @param selectedMediumFormat
   * @param agencyType
   */
  public static getOptions = (
    selectedMediumFormat: MediaFormat,
    agencyType?: AgencyType
  ): SelectOption[] => {
    const isInternalAgency = agencyType === AgencyType.internal

    const additionalOffers =
      isInternalAgency && selectedMediumFormat.toLowerCase().includes('dgin')
        ? Object.values(ADDITIONAL_OFFER)
        : []

    return [...Object.values(OFFER), ...additionalOffers]
      .filter(offerOption => {
        /**
         * we remove the buildings option for DG_CL and DG_CS
         */
        if (
          selectedMediumFormat === MediaFormat.DG_CL ||
          selectedMediumFormat === MediaFormat.DG_CS
        ) {
          return offerOption !== OFFER.BUILDINGS
        }
        return true
      })
      .map(
        (offerOption: OFFER | ADDITIONAL_OFFER): SelectOption => ({
          value: offerOption,
          label: i18n.t(`offer.${offerOption}`),
          isDisabled: this.isAdditionalOffer(offerOption),
        })
      )
  }

  public static isAdditionalOffer = (offer: string): boolean => {
    return Object.values(ADDITIONAL_OFFER).includes(offer as ADDITIONAL_OFFER)
  }
}
